<template>
  <div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="tableLoading" @change="tableChange">
      <template #isUnbind="{ record }">
        <span :class="record.isUnbind.value == 0 ? 'isUnbindLabel' : ''"> {{record.isUnbind.label}}</span>
      </template>
      <template #operate="{ record }">
        <div>
          <a @click="deleteBtn(record)">删除</a>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { virtualOrderList as page } from '@/api/virtualNumber/virtualNumber'
import { reactive, toRefs, watch } from 'vue'
import { confirmWindow } from '@/utils/util'
import { message } from 'ant-design-vue'

export default ({
  props: { orderId: String },
  setup (props, context) {
    const state = reactive({
      loading: false,
      visible: false,
      tableLoading: false,
      orderId: props.orderId,
      appletMobile: undefined,
      listData: [],
      columns: [
        {
          title: '绑定类型',
          dataIndex: 'bindType.label'
        },
        {
          title: '使用场景',
          dataIndex: 'sceneType.label'
        },
        {
          title: '真实号码',
          dataIndex: 'bindNumberA'
        },
        {
          title: '虚拟号',
          dataIndex: 'middleNumber'
        },
        {
          title: '分机号',
          dataIndex: 'ext'
        },
        {
          title: '绑定人',
          dataIndex: 'creator'
        },
        {
          title: '绑定时间',
          dataIndex: 'createTime'
        },
        {
          title: '状态',
          dataIndex: 'isUnbind.label',
          slots:{customRender: 'isUnbind'}
        },
        {
          title: '解绑人',
          dataIndex: 'unbindPerson'
        },
        {
          title: '解绑时间',
          dataIndex: 'unbindTime'
        }
      ],
      pagination: {
        current: 1,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    watch(() => props.orderId, (newVal, oldVal) => {
      state.orderId = props.orderId
    })

    const loadData = () => {
      state.tableLoading = true
      page({orderId: state.orderId}).then(res => {
        state.listData = res.data
        state.total = res.data.length
      }).finally(() => { state.tableLoading = false })
    }
    const tableChange = page => {
      state.pagination = page
      loadData()
    }
    return {
      ...toRefs(state),
      loadData,
      tableChange
    }
  }
})
</script>
<style lang="less" scoped>
.isUnbindLabel{
  color: #15AD31;
}
</style>