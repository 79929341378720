<template>
  <div>
    <a-modal :visible="visible" title="拆分订单" width="45%" footer="" @cancel="cancel">
      <div>订单全部车辆<span style="color:#Db0303">（选择的车辆会保留在当前订单，未选择的车辆将重新生成一个新的订单）</span></div>
      <div class="m-t1 tabBox">
        <a-table :rowKey="(record, index) => { return index }" :pagination="false" size="small" :dataSource="splitData.vehicleList" bordered :columns="columns"   :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
          <template #vinNo="{record}">
            {{ record.vinNo }} {{ record.brand }} {{ record.model  }}
          </template>
          <template #transStatus="{record}">
            {{ record.transportType.label }}：{{record.transStatus.label  }}
          </template>
        </a-table>
      </div>
      <div class="m-t3 flex ju-end">
        <a-button type="primary" @click="handleOk">确定</a-button>
      </div>
    </a-modal>
    <a-modal v-model:visible="visibleTips" title="提示" okText="确认拆分"  @Ok="save">
       <p  style="color:#B30000">已选车辆{{selectedText}}将保留在当前订单</p>
       <p  style="color:#B30000" class="m-t2">剩余车辆{{notSelectedText}}将生成新的订单</p>
    </a-modal>  
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { splitVehicle } from '@/api/transport/documentary'
import { message } from 'ant-design-vue';
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  splitData: {
    type: Object,
    default: {}
  }
})
const emit = defineEmits(['update:visible'])
const selectedRowKeys  = ref([])
const visibleTips = ref(false)
const selectedText = ref('')
const notSelectedText = ref('')
const cancel = () => {
  emit('update:visible',false)
}
const save = () => {
  let listIds = []
  props.splitData.vehicleList.forEach( (item,index) => {
    if(selectedRowKeys.value.includes(index)){
      listIds.push(item.orderVehicleId)
    }
  })
  splitVehicle({
    orderId: props.splitData.orderId,
    orderVehicleIds:listIds
    }).then( res => {
    if(res.code !== 10000) return
    message.success('操作成功')
    visibleTips.value = false
     cancel()
     emit('splitOk')
  })
}
const onSelectChange = (e) => {
  selectedRowKeys.value = e
}
// 确定
const handleOk = () => {
  if(selectedRowKeys.value.length == 0){
    message.warn('请先选择需要拆分的车辆')
    return
  }
  selectedText.value  =  ''
  notSelectedText.value = ''
  let list = []
  let notList = []
  props.splitData.vehicleList.forEach( (item,index) => {
    if(selectedRowKeys.value.includes(index)){
      list.push(item)
    }else{
      notList.push(item)
    }
  })
  if(notList.length == 0) {
    message.warn('你已选择全部车辆，不能拆分')
    return
  }
  list.forEach(opt => {
    selectedText.value +=  `【${opt.vinNo}】`
  })
  notList.forEach(opt => {
    notSelectedText.value +=  `【${opt.vinNo}】`
  })
  console.log(list,notList);
  visibleTips.value = true
}
const columns = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    slots:{
      customRender:'vinNo'
    }
  },
  {
    title: '调度员',
    dataIndex: 'currentShipper',
  },
  {
    title: '发运状态',
    dataIndex: 'transStatus',
    slots:{
      customRender:'transStatus'
    }
   
  },

])
</script>

<style lang="less" scoped>
.tabBox{
 max-height: 500px;
 overflow: auto;
}
</style>