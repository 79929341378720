<template>
  <div>
    <a-form layout="vertical">
      <a-form-item label="客户小程序电话号码">
        <a-input allowClear v-model:value="appletMobile" placeholder="请输入" />
      </a-form-item>
      <a-form-item>
        <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onOK" :disabled="loading">
          关联到客户小程序订单
        </a-button>
        <a-tag  color="red" >请注意（不支持内部员工账号关联客户的小程序订单）</a-tag>
      </a-form-item>
    </a-form>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="tableLoading" @change="tableChange">
      <template #operate="{ record }">
        <div>
          <a @click="deleteBtn(record)">删除</a>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { relatedOrderToAppletOrder, appletPage,deleteApple } from '@/api/BEnd/appletPublic'
import { reactive, toRefs, watch } from 'vue'
import { confirmWindow } from '@/utils/util'
import { message } from 'ant-design-vue'

export default ({
  props: { orderId: String },
  setup (props, context) {
    const state = reactive({
      loading: false,
      visible: false,
      tableLoading: false,
      orderId: props.orderId,
      appletMobile: undefined,
      listData: [],
      columns: [
        {
          title: '手机号码',
          dataIndex: 'mobile'
        },
        {
          title: '关联时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作人',
          dataIndex: 'creator'
        },
        {
          title: '操作',
          dataIndex: 'operate',
          slots: {
            customRender: 'operate'
          }
        },
      ],
      pagination: {
        current: 1,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    watch(() => props.orderId, (newVal, oldVal) => {
      state.orderId = props.orderId
    })

    const loadData = () => {
      state.tableLoading = true
      appletPage({
        orderId: state.orderId,
        current: state.pagination.current,
        size: state.pagination.size
      }).then(res => {
        state.listData = res.data.records
        state.total = res.data.total
      }).finally(() => { state.tableLoading = false })
    }
    const onOK = () => {
      if (state.appletMobile === '' || state.appletMobile === undefined) {
        message.warn('请输入客户电话')
        return
      } else if (state.appletMobile.length !== 11) {
        message.warn('客户电话长度不对')
        return
      }
      state.loading = true
      relatedOrderToAppletOrder({
        appletMobile: state.appletMobile,
        orderId: state.orderId
      }).then(res => {
        if (res.code === 10000) {
          state.appletMobile = undefined
          state.visible = true
          state.loading = false
          message.success('关联订单到小程序订单成功')
          loadData()
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const deleteBtn = record => {
      confirmWindow('确认删除',requireAPI)
      function requireAPI(resolve) {
        deleteApple({
          appletUserId: record.appletUserId,
          orderId: state.orderId
        }).then(res=>{
          if(res.code === 10000){
            message.success('删除成功')
            loadData()
          }
        }).finally(()=>{resolve()})
      }
    }
    const tableChange = page => {
      state.pagination = page
      loadData()
    }
    return {
      ...toRefs(state),
      onOK,
      loadData,
      deleteBtn,
      tableChange
    }
  }
})
</script>
